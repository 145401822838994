@import '../../../../theme/base/colors';
@import '../../../../theme/objects/buttons';

$sidePanelToolbarInputBorderColor: darken($light-gray, 10%);

.cpdSidePanel__header__content {
  display: flex;
  justify-content: space-between;
}

.cpdToolbar {
  display: flex;
  align-items: center;
  font-size: 0.5em;

  --novo-field-padding: 0;
  --novo-field-margin: 0 1em 0 0;
  --novo-field-input-height: auto;
  --novo-field-input-padding: 0.425em 1em;
  --novo-field-input-border-color: #{$sidePanelToolbarInputBorderColor};
  --novo-button-small-action-padding-left: 0.9em;
  --novo-button-small-action-padding-right: 0.9em;

  .novoButton {
    @extend .cpdButtonWithIcon;

    margin-right: 0.5em;
    text-decoration: none;

    .material-icons {    /* stylelint-disable-line */
      left: 0;
    }

    &:last-child {
      margin-right: 1.25em;
    }
  }
}

.cpdAdditionalNotes {
  margin: 2em -0.75em 0;
}

@media print {
  * {
    box-shadow: none;
  }

  .cpdAdminLayout__content {
    display: block;
    height: 100%;
    padding: 0;
  }

  .cpdAdminHeader,
  .cpdAdminLayout__content__header,
  .ag-theme-alpine {    /* stylelint-disable-line */
    display: none;
  }

  .novoBlanket {
    position: relative;
    background: none;
  }

  .novoSidePanel {
    position: relative;
    max-width: unset;
    height: fit-content;
    min-height: unset;
    max-height: unset;
    overflow: visible;
    box-shadow: none;
  }

  .novoSidePanel__header-nav--host,
  .novoSidePanel__footer {
    display: none;
  }

  .novoTabPanel {
    display: none;
  }

  .novoTabPanel[id$='infos'],
  .novoTabPanel[id$='subvention'] {
    display: block;
  }

  .novoSidePanel__content,
  .novoSidePanel > .novoForm {
    overflow-y: visible;
  }

  .novoSidePanel__header {
    button,
    .cpdToolbar {
      display: none;
    }
  }
}
