.cpdTooltip {
  display: flex;
}

.cpdTooltip__tooltip {
  font-weight: normal;
  line-height: 1.75em;
  text-align: center;
  transition: none !important;
}
