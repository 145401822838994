@import '../base/colors';
@import '../helpers/units';

$tagFontSize: 12px;
$tagDefaultBackground: $light-gray;
$tagPrimaryBackground: $dark-blue;
$tagSuccessBackground: $green;
$tagWarningBackground: $yellow;
$tagDangerBackground: $red;
$tagNeutralBackground: $medium-gray;
$tagInfoBackground: $blue;
$tagDarkBackground: rgb(127 140 143);
$tagDarkerBackground: $dark-gray;

.cpdTag {
  display: inline-block;
  padding: em(3px, $tagFontSize) em(16px, $tagFontSize);
  overflow: hidden;
  font-size: em($tagFontSize);
  font-weight: 600;
  line-height: em(16px, $tagFontSize);
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  white-space: nowrap;
  vertical-align: sub;
  user-select: none;
  background-color: $tagDefaultBackground;
  border-radius: em(10px);
}

.cpdTag--full-width {
  width: 100%;
}

.cpdTag--primary {
  color: $white;
  background-color: $tagPrimaryBackground;
}

.cpdTag--success {
  color: $white;
  background-color: $tagSuccessBackground;
}

.cpdTag--danger {
  color: $white;
  background-color: $tagDangerBackground;
}

.cpdTag--warning {
  color: $white;
  background-color: $tagWarningBackground;
}

.cpdTag--neutral {
  color: $white;
  background-color: $tagNeutralBackground;
}

.cpdTag--info {
  color: $white;
  background-color: $tagInfoBackground;
}

.cpdTag--dark {
  color: $white;
  background-color: $tagDarkBackground;
}

.cpdTag--darker {
  color: $white;
  background-color: $tagDarkerBackground;
}
