@import '../../../../theme/helpers/units';
@import '../../../../theme/base/colors';

$headerPrimarySectionBackgroundColor: $white;
$headerSecondarySectionBackgroundColor: $blue;
$headerLogoWidth: 104px;
$headerLogoHeight: 60px;
$headerLogoMarginRight: 108px;
$headerPaddingTopBottom: 17px;

.cpdAdminHeader__section {
  display: flex;
  padding: em($headerPaddingTopBottom) em(30px) 0 em(24px);
  background-color: $headerPrimarySectionBackgroundColor;
}

.cpdAdminHeader__section--secondary {
  min-height: em(48px);
  padding-top: em(8px);
  background-color: $headerSecondarySectionBackgroundColor;
}

.cpdAdminHeader__section__logo {
  width: em($headerLogoWidth);
  height: em($headerLogoHeight);
  margin-top: em(-$headerPaddingTopBottom + 8px);
  margin-right: em($headerLogoMarginRight);
}

.cpdAdminHeader__section__navigation {
  flex-grow: 2;
}

.cpdAdminHeader__section__navigation .cpdMenu--submenu {
  margin-left: em($headerLogoWidth + $headerLogoMarginRight);
}

.cpdAdminHeader__section__currentUser {
  display: flex;
  align-items: center;
  margin-left: auto;
}
