.cpdIcon--add::before {
  content: 'add';
}

.cpdIcon--export::before {
  content: 'get_app';
}

.cpdIcon--filterOff {
  width: 0.9em;
  height: 24px;
  background: url('~/public/assets/filter-off.svg') no-repeat center;
}

.cpdIcon--refresh::before {
  content: 'refresh';
}

.cpdIcon--print::before {
  content: 'print';
}

.cpdIcon--edit::before {
  content: 'edit';
}

.cpdIcon--contact::before {
  content: 'email';
}

.cpdIcon--lock {
  margin-top: 0.6em;
  font-size: 19px;
  color: $dark-gray;
}

.cpdIcon--locked::before {
  content: 'lock';
}

.cpdIcon--unlocked::before {
  content: 'lock_open';
}
