@import '../../../../theme/base/elevations';
@import '../../../../theme/base/colors';
@import '../../../../theme/helpers/units';
@import '../../../../theme/base/sizes';

$menuResponsiveControlColor: $blue;
$menuResponsiveControlFontSize: 26px;
$menuResponsiveMobileMaxWidth: 280px;

.cpdMenuResponsive__panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: em($menuResponsiveMobileMaxWidth);
  height: 100vh;
  padding: em(32px);
  overflow-y: auto;
  text-align: center;
  background-color: white;
  box-shadow: $elevation-04;
  animation: menuResponsiveSlideIn 150ms forwards;
  animation-delay: 0s;

  .cpdMenu {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: em(32px);
  }

  .cpdMenu__item {
    margin: 0 0 em(36px);
  }

  .cpdMenu__item,
  .cpdMenu__item__link {
    text-align: right;
  }
}

.cpdMenuResponsive__panel__close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.cpdMenuResponsive__control,
.cpdMenuResponsive__panel__close {
  padding: 0;
  font-size: em($menuResponsiveControlFontSize);
  color: $menuResponsiveControlColor;
  cursor: pointer;
  background: none;
  border: 0;

  &:hover {
    opacity: 0.85;
  }
}

.cpdMenuResponsive--home .cpdMenuResponsive__control {
  color: white;
}

/* stylelint-disable */
@keyframes menuResponsiveSlideIn {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}
/* stylelint-enable */
