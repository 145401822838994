@import '../../../../theme/helpers/units';

$recuperationLinkFormButtonFontSize: 18px;

.cpdRecuperationLinkForm {
  --novo-field-padding: 0;

  .novoForm {
    padding-top: 0;
  }

  .novoButton {
    margin-bottom: em(34px, $recuperationLinkFormButtonFontSize);
  }
}
