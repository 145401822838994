@import '../../../theme/helpers/units';
@import '../../../theme/base/colors';

$periodAmountsTableHeadBgColor: $light-gray;
$periodAmountsTableHeadFontSize: 14px;
$periodAmountsTableRowBgColor: $light-gray;
$periodAmountsTableOddRowBorderColor: $lightest-gray;

.cpdPeriodAmountsTable {
  --novo-field-margin: 0;
  --novo-field-padding: 0;

  width: calc(100% + #{3em});
  margin: 0 -1.5em em(24px);
}

.cpdPeriodAmountsTable__head {
  font-size: em($periodAmountsTableHeadFontSize);
  font-weight: bold;
  line-height: em(20px, $periodAmountsTableHeadFontSize);
  background-color: $periodAmountsTableHeadBgColor;
}

.cpdPeriodAmountsTable__head__cell,
.cpdPeriodAmountsTableRow__cell {
  padding: rem(8px) rem(24px) rem(9px);
}

.cpdPeriodAmountsTableRow {
  border-bottom: 1px solid $periodAmountsTableRowBgColor;
}

.cpdPeriodAmountsTableRow:nth-child(odd) {
  background-color: $periodAmountsTableOddRowBorderColor;
}

.cpdPeriodAmountsTable__head__cell {
  text-align: left;
}

.cpdPeriodAmountsTableRow__cell:last-child {
  text-align: right;
}

.cpdPeriodAmountsButtons {
  display: flex;
  justify-content: space-between;
}

.cpdTag--period--ongoing {
  color: $white;
  background-color: $orange;
}

.cpdTag--period--forthcoming {
  color: $white;
  background-color: $green;
}
