@import '../helpers/units';

.cpdButtonWithIcon {
  display: inline-flex;
  align-items: center;

  .material-icons {    /* stylelint-disable-line */
    position: relative;
    left: em(-4px);
    font-size: 1.25em;
  }
}
