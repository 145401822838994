@import '../../theme/helpers/units';
@import '../../theme/base/colors';
@import '../../theme/base/sizes';

$homePagePanelBgColor: $dark-blue;
$homePagePanelTitleFontSize: 20px;
$homePageButtonPrimaryBgColor: $orange;
$homePageButtonPrimaryHoveredBgColor: darken($orange, 5%);
$homePageButtonPrimaryFontSize: 18px;
$homePageButtonPrimaryMobileFontSize: 16px;
$homePageButtonSecondaryBgColor: $homePagePanelBgColor;
$homePageButtonSecondaryBorderColor: $blue;
$homePageButtonSecondaryFontSize: 14px;
$homePageButtonSecondaryHoveredBgColor: darken($homePagePanelBgColor, 5%);
$homePageOrganizationItemHeight: 120px;

.cpdHomePagePanel {
  --novo-button-primary-bg-color: #{$homePageButtonPrimaryBgColor};
  --novo-button-primary-border-color: #{$homePageButtonPrimaryBgColor};
  --novo-button-primary-hovered-bg-color: #{$homePageButtonPrimaryHoveredBgColor};
  --novo-button-primary-hovered-border-color: #{$homePageButtonPrimaryHoveredBgColor};
  --novo-button-font-size: #{em($homePageButtonPrimaryFontSize)};
  --novo-button-bg-color: #{$homePageButtonSecondaryBgColor};
  --novo-button-hovered-bg-color: #{$homePageButtonSecondaryHoveredBgColor};
  --novo-button-color: white;
  --novo-button-hovered-color: white;
  --novo-button-border: 2px solid #{$homePageButtonSecondaryBorderColor};
  --novo-button-margin: 0 0 #{em(14px)};

  padding: em(20px);
  margin: em(28px) 0 0 em(-20px);
  color: white;
  background-color: rgba($homePagePanelBgColor, 0.3);
}

.cpdHomePagePanel__title {
  margin-bottom: em(8px, $homePagePanelTitleFontSize);
  font-size: em($homePagePanelTitleFontSize);
  font-weight: 700;
  line-height: em(28px, $homePagePanelTitleFontSize);
}

.cpdHomePagePanel__text,
.cpdHomePageDocumentsList__item {
  line-height: 1.5em;
}

.cpdHomePagePanel__text {
  margin-bottom: em(14px);
}

.cpdHomePagePanel__text__link {
  font-weight: 600;
  color: inherit;
}

.cpdHomePagePanel .cpdHomePagePanel {
  --novo-button-margin: 0;

  padding: 1em;
  margin: 0 0 em(10px);
  background-color: $homePagePanelBgColor;

  .novoButton--primary {
    margin-right: em(18px, $homePageButtonPrimaryFontSize);
    margin-bottom: 0;
  }

  .cpdHomePagePanel__title {
    font-size: 1em;
    text-transform: uppercase;
  }

  .cpdHomePagePanel__text + .cpdHomePagePanel__text {
    margin-top: em(-12px);
  }
}

.cpdHomePageDocumentsList {
  padding-left: 2.25em;
  margin-top: em(24px);
}

.cpdHomePageDocumentsList__item {
  margin-bottom: em(8px);

  &::before {
    position: absolute;
    display: inline-block;
    font-family: 'Material Icons', sans-serif;
    font-size: 1.5em;
    content: 'insert_drive_file';
    transform: translateX(-1.25em);
  }
}

.cpdHomePageDocumentsList__item__link {
  color: inherit;
}

.cpdHomePageFooter {
  max-width: em(1040px);
  margin: em(24px) auto;
}

.cpdHomePageFooter__title {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: -0.01em;
}

.cpdHomePageFooter__organizations {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: -1em;
}

.cpdHomePageFooter__organizations__item {
  display: flex;
  align-items: center;
  min-height: em($homePageOrganizationItemHeight);
}

.cpdHomePageFooter__organizations__item__logo {
  max-height: em(70px);
  padding: 1em;
}

.cpdHomePageFooter__organizations__item__logo--qesba {
  max-height: em($homePageOrganizationItemHeight);
}

.cpdHomePageFooter__organizations__item__logo--amdes {
  max-height: em(90px);
}

.cpdHomePagePanel__text__link:hover,
.cpdHomePageDocumentsList__item__link:hover {
  color: darken(white, 8%);
}

@media screen and (max-width: $large-screen-width) {
  .cpdHomePageFooter {
    padding: 0 1.5em;
  }
}

@media screen and (max-width: $small-screen-width) {
  .cpdHomePagePanel {
    --novo-button-font-size: #{em($homePageButtonPrimaryMobileFontSize)};

    margin-right: em(-20px);
  }
}
