@import '../../../../theme/helpers/units';
@import '../../../../theme/base/colors';
@import '../../../../theme/base/sizes';

$menuItemColor: $dark-blue;
$menuItemSelectedColor: $blue;
$adminMenuItemColor: $dark-gray;
$menuItemFontSize: 16px;

.cpdMenu {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.cpdMenu__item {
  display: flex;
  align-items: stretch;
  font-size: em($menuItemFontSize);
  line-height: em(24px, $menuItemFontSize);
  color: $menuItemColor;

  &:hover {
    color: darken($menuItemColor, 15%);
  }

  &:not(:last-child) {
    margin-right: em(24px, $menuItemFontSize);
  }
}

.cpdMenu__item--selected {
  padding-bottom: em(2px, $menuItemFontSize);
  color: $menuItemSelectedColor;
  pointer-events: none;
  border-bottom: em(8px, $menuItemFontSize) solid rgba($menuItemSelectedColor, 0.3);

  &:hover {
    color: $menuItemSelectedColor;
  }
}

.cpdMenu__item__link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: inherit;
  text-align: center;
  text-decoration: none;
}

.cpdMenu__item__button {
  padding: 0;
  font-size: inherit;
  color: inherit;
  text-align: center;
  cursor: pointer;
  background: none;
  border: 0;
}

.cpdMenu__item--selected .cpdMenu__item__link {
  cursor: default;
}

.cpdMenu--admin {
  align-items: stretch;

  .cpdMenu__item {
    padding: 0;
    font-weight: 700;
    color: $adminMenuItemColor;
    text-transform: uppercase;
    border: 0;

    &:not(:last-child) {
      margin-right: em(16px, $menuItemFontSize);
    }
  }

  .cpdMenu__item__link {
    padding: em(16px) em(24px);
  }

  .cpdMenu__item--selected {
    color: white;
    background-color: $menuItemSelectedColor;
  }
}

.cpdMenu--admin.cpdMenu--submenu {
  .cpdMenu__item {
    color: white;
    text-transform: none;
  }

  .cpdMenu__item__link {
    padding: em(8px) em(16px);
  }

  .cpdMenu__item--selected {
    color: black;
    background-color: white;
  }
}

@media screen and (min-width: $medium-screen-width + 1px) {
  .cpdMenu--home {
    .cpdMenu__item,
    .cpdMenu__item--selected {
      color: white;
    }

    .cpdMenu__item:hover {
      color: darken(white, 8%);
    }

    .cpdMenu__item--selected {
      border-color: rgba(white, 0.25);
      border-width: 3px;
    }
  }
}
