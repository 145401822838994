@import '../../../../theme/helpers/units';
@import '../../../../theme/objects/inputs';

@media print {
  @page {
    margin: 0.5in;
  }

  :root {
    --novo-side-panel-header-title-font-size: 1.5em;
    --novo-fields-title-font-size: 0.95em;
    --novo-subfields-title-font-size: 85em;
    --novo-field-input-font-size: 14px;
    --novo-field-description-font-size: 12px;
    --novo-field-label-font-size: 12px;
    --novo-field-label-required-symbol: '';
    --novo-checkbox-label-font-size: 14px;
    --novo-side-panel-header-padding: 1em 1em 0 1.5em;
    --novo-side-panel-header-nav-padding: 1em 1em 0 1.5em;
    --novo-side-panel-content-padding: 1em 1em 0 1.5em;
    --novo-fields-padding: 0.85em 0.25em;
    --novo-fields-margin: 0 auto 1em;
    --novo-field-padding: 0 0.5em;
    --novo-field-margin: 0 0 0.75em;
  }

  .cpdFormPrint {
    .novoForm {
      padding-top: 0;
    }

    .novoForm__fields {
      display: block;
      break-inside: avoid;
      print-color-adjust: exact !important;
    }

    .novoForm__fields__title::after {
      content: '';
    }

    .novoFormField {
      display: inline-block;
      break-inside: avoid;
    }

    .novoFormField--files-list {
      display: none;
    }

    .novoFormFieldLabel {
      margin: var(--novo-field-label-readonly-margin);
      cursor: text;
    }

    .novoFormFieldTooltip {
      display: none;
    }

    .novoFormField__description {
      display: none;
    }

    .novoFormField__input {
      padding: var(--novo-field-input-readonly-padding);
      color: var(--novo-field-input-color);
      cursor: text;
      background-color: transparent;
      border: 0;
      border-radius: 0;
    }

    .novoInputWithSuffix__suffix,
    .novoInputWithSuffix .novoFormField__input {
      display: none;
    }

    .novoFormField__raw {
      display: block;
    }

    .novoFormField__raw:empty::before {
      content: '—';
    }

    .novoFormField__input--select {
      height: auto;
      background-image: none;
    }

    .novoFormField__input--text-area {
      display: none;
    }

    .novoInputWithSuffix {
      display: block;
      padding: 0;
      background-color: transparent;
      border: 0;
    }

    .novoInputWithButton .novoInputWithButton__button {
      display: none;
    }

    .novoDropzone__zone,
    .novoFileUploaded__status,
    .novoFileUploaded__actions__button {
      display: none;
    }

    .novoNumberField {
      background-color: transparent;
      border: 0;
    }

    .novoNumberField__controls__button {
      display: none;
    }

    .novoFormOptionsField .novoFormField {
      margin: 0;
    }

    .novoFormOptionsField--inline .novoFormField {
      margin-right: em(16px);
    }

    .novoButton[type='submit'] {
      display: none;
    }

    .novoTooltip {
      display: none;
    }

    @include readonly-checkbox;
  }
}
