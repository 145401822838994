@import '../../../theme/helpers/units';

.cpdSignInForm {
  --novo-field-padding: 0;
}

.cpdSignInForm .cpdFormField {
  padding: 0;
  margin-bottom: em(34px);
}

.cpdSignInForm .cpdFormField__input:disabled {
  margin-bottom: -1em;
}

.cpdSignInForm__footer {
  display: flex;
  align-items: center;
  margin-top: em(25px);
}

.cpdSignInForm__footer__col {
  flex: 1;
}

.cpdSignInForm__footer__col--back {
  text-align: right;
}
