@import '../../../../theme/base/colors';
@import '../../../../theme/helpers/units';

$userInfoColor: $dark-gray;
$userInfoFontSize: 16px;
$userInfoLineHeight: 24px;
$userInfoIconColor: $medium-gray;
$userActionsColor: $dark-gray;
$userActionsBackgroundColor: $white;
$userActionsBackgroundHColorHovered: darken($white, 3%);
$userActionsBorderColor: $medium-gray;

.cpdCurrentUserMenu {
  position: relative;
}

.cpdCurrentUserMenu__userInfo {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: em($userInfoFontSize);
  font-weight: 600;
  line-height: em($userInfoLineHeight, $userInfoFontSize);
  color: $userInfoColor;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;

  &:hover {
    opacity: 0.9;
  }
}

.cpdCurrentUserMenu__userInfo__profile {
  margin-right: em(10px, 24px);
  color: $userInfoIconColor;
}

.cpdCurrentUserMenu__userInfo__chevronDown {
  margin-left: em(5px, 24px);
  color: $userInfoIconColor;
}

.cpdCurrentUserMenu__actions {
  position: absolute;
  width: 100%;
  margin-top: em($userInfoLineHeight * 0.4);
  overflow: hidden;
  background-color: $userActionsBackgroundColor;
  border: 1px solid $userActionsBorderColor;
  box-shadow: 0 em(3px) em(6px) rgb(0 0 0 / 10%);
}

.cpdCurrentUserMenu__actions__item {
  padding: em(10px);
  color: $userActionsColor;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $userActionsBorderColor;
  }

  &:hover {
    background-color: $userActionsBackgroundHColorHovered;
  }
}

.cpdCurrentUserMenu__actions__item__button {
  width: 100%;
  padding: 0;
  font-size: em(14px);
  line-height: em(24px);
  color: inherit;
  text-align: left;
  cursor: inherit;
  background-color: transparent;
  border: 0;
  outline: 0;
}
