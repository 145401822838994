@import '../../../../theme/helpers/units';
@import '../../../../theme/objects/buttons';

.cpdGridToolbar {
  display: flex;
}

.cpdGridToolbar > .novoButton,
.cpdGridToolbar > .cpdDropdownButton {
  @extend .cpdButtonWithIcon;

  margin-left: em(16px);
}

.cpdGridToolbar > .cpdDropdownButton {
  display: inline-block;
}
