@import '../../../../theme/base/colors';

.novoFormField--files-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .novoFormField__name {
    flex-basis: 100%;
  }

  .cpdTooltip {
    margin-left: auto;
  }

  .novoButton {
    padding: 0 0.5em;
    font-size: 1em;
  }
}

.cpdFilesList {
  --novo-file-uploaded-bg-color-hovered: #{$light-gray};

  flex-basis: 100%;
  margin-bottom: 0.5em;
}

.cpdFilesList__item:not(:last-child) .novoFileUploaded {
  border-bottom: 0;
}

.cpdFilesEdit {
  --novo-fields-margin: 0;
  --novo-field-margin: 0;
}
