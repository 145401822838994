@import '../../theme/helpers/units';
@import '../../theme/base/colors';
@import '../../theme/base/sizes';

$importantDatesTitleFontSize: 24px;
$importantDatesSectionTitleFontSize: 20px;
$importantDatesTitleColor: $blue;
$importantDatesPanelBgColor: $lightest-gray;

.cpdImportantDates {
  margin-top: em(32px);
}

.cpdImportantDates__title {
  margin-bottom: em(12px, $importantDatesTitleFontSize);
  font-size: em($importantDatesTitleFontSize);
  font-weight: 600;
  line-height: em(32px, $importantDatesTitleFontSize);
  color: $importantDatesTitleColor;
  letter-spacing: -0.01em;
}

.cpdImportantDates__section {
  margin-bottom: 2em;
}

.cpdImportantDates__section__title {
  margin-bottom: 0.25em;
  font-size: em($importantDatesSectionTitleFontSize);
  font-weight: 600;
  line-height: em(28px, $importantDatesTitleFontSize);
  letter-spacing: -0.01em;
}

.cpdImportantDates__section__text {
  margin: 1em 0;
  font-size: 1em;
  line-height: 1.5em;

  strong {
    display: block;
    margin-bottom: 0.5em;
  }

  .cpdSiteLayout__panel__content__text {
    margin: 1em 0;
  }
}

.cpdImportantDatesPanel {
  padding: em(16px);
  margin: em(20px) 0 em(24px);
  background-color: $importantDatesPanelBgColor;
}

.cpdImportantDatesPanel__title {
  font-weight: 600;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: -0.01em;
}

@media screen and (max-width: $small-screen-width) {
  .cpdImportantDates__title {
    font-size: em(20px);
  }

  .cpdImportantDates__section__title {
    font-size: em(17px);
  }
}
