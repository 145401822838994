@import '../../../../theme/helpers/units';
@import '../../../../theme/base/colors';

$submissionTypeBgColor: $white;
$submissionTypeDisabledBgColor: $lightest-gray;
$submissionTypeCheckColor: $white;
$submissionDescriptionEmphasisColor: $blue;
$submissionBorderColor: #a6afb1;
$submissionTextColor: black;
$submissionDisabledTextColor: lighten($dark-gray, 15%);
$submissionTitleFontSize: 16px;
$submissionDescriptionFontSize: 14px;
$submissionLinkFontSize: 14px;
$submissionIconLinkFontSize: 20px;
$submissionTypeCheckFontSize: 40px;
$submissionTypeSelectedColor: $white;
$submissionTypeSelectedBgColor: $blue;
$submissionLinkColor: $blue;

.cpdSubmissionType {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: em(14px);
  line-height: em(18px);
  color: $submissionTextColor;
  cursor: pointer;
  user-select: none;
  background-color: $submissionTypeBgColor;
  border: 1px solid $submissionBorderColor;
  border-radius: 8px;
}

.cpdSubmissionType:hover {
  border-color: $submissionTypeSelectedBgColor;
}

.cpdSubmissionType__content {
  flex-grow: 1;
  pointer-events: none;
}

.cpdSubmissionType__content__selection-mark {
  float: right;
  padding-left: em(6px, $submissionTypeCheckFontSize);
  font-size: em($submissionTypeCheckFontSize);
  color: $submissionTypeCheckColor;
  visibility: hidden;
}

.cpdSubmissionType__content__title {
  margin-bottom: em(8px, $submissionTitleFontSize);
  font-size: em($submissionTitleFontSize);
  font-weight: 700;
  line-height: em(24px, $submissionTitleFontSize);
}

.cpdSubmissionType__content__description,
.cpdSubmissionType__content__activities,
.cpdSubmissionType__footer__type,
.cpdSubmissionType__footer__link {
  margin-bottom: em(8px, $submissionDescriptionFontSize);
  font-size: em($submissionDescriptionFontSize);
  line-height: em(18px, $submissionDescriptionFontSize);
}

.cpdSubmissionType__content__activities {
  padding-left: 0.75em;
}

.cpdSubmissionType__content__activities__item {
  padding-bottom: 0.1em;
  margin: 0.25em 0;

  &::before {
    position: absolute;
    content: '\2022';
    transform: translateX(-0.75em);
  }
}

.cpdSubmissionType__footer {
  margin-top: em(44px);
}

.cpdSubmissionType__footer__type {
  font-weight: 600;
}

.cpdSubmissionType__footer__link {
  display: flex;
  align-items: flex-start;
  font-size: em($submissionLinkFontSize);
  color: $submissionLinkColor;
  text-align: left;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
}

.cpdSubmissionType__footer__link__icon {
  padding-right: em(6px, $submissionIconLinkFontSize);
  font-size: em($submissionIconLinkFontSize, $submissionLinkFontSize);
}

.cpdSubmissionType__footer__link:hover .cpdSubmissionType__footer__link__text {
  text-decoration: underline;
}

.cpdSubmissionType--selected .cpdSubmissionType__content__selection-mark {
  visibility: visible;
}

.cpdSubmissionType--selected {
  color: $submissionTypeSelectedColor;
  cursor: default;
  background-color: $submissionTypeSelectedBgColor;

  .cpdSubmissionType__content__description--emphasis,
  .cpdSubmissionType__footer__link {
    color: inherit;
  }
}

.cpdSubmissionType__content__description--emphasis {
  font-weight: 600;
  color: $submissionDescriptionEmphasisColor;
}

.cpdSubmissionType--disabled {
  color: $submissionDisabledTextColor;
  pointer-events: none;
  background-color: $submissionTypeDisabledBgColor;

  &:hover {
    border-color: $submissionBorderColor;
  }

  &.cpdSubmissionType--selected {
    color: inherit;
    background-color: $submissionTypeDisabledBgColor;
  }

  .cpdSubmissionType__content__description--emphasis {
    color: inherit;
  }

  .cpdSubmissionType__content__selection-mark {
    color: $submissionTypeSelectedBgColor;
  }

  .cpdSubmissionType__footer__link {
    color: $submissionDisabledTextColor;
  }
}

.novoForm__fields--readonly .cpdSubmissionType--disabled {
  background-color: $submissionTypeBgColor;

  .cpdSubmissionType__footer {
    display: none;
  }

  .cpdSubmissionType__content__description--emphasis {
    color: $submissionDescriptionEmphasisColor;
  }
}
