@import '../../../../theme/base/colors';
@import '../../../../theme/base/elevations';
@import '../../../../theme/base/sizes';
@import '../../../../theme/helpers/units';

$stickyBoxColor: $white;
$stickyBoxBgColor: $dark-blue;
$stickyBoxTitleFontSize: 18px;

.cpdStickyBox {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: em(16px) 3em em(19px);
  margin: 1.25em -3em;
  color: $stickyBoxColor;
  background-color: $stickyBoxBgColor;
  box-shadow: $elevation-02;
}

.cpdStickyBox__title,
.cpdStickyBox__description {
  letter-spacing: -0.01em;
}

.cpdStickyBox__title {
  margin-bottom: 0.4em;
  font-size: em($stickyBoxTitleFontSize);
  font-weight: bold;
  line-height: em(28px);
  text-transform: uppercase;
}

.cpdStickyBox__description {
  line-height: em(24px);
}

@media screen and (max-width: $small-screen-width) {
  .cpdStickyBox__title {
    display: none;
  }

  .cpdStickyBox__description {
    font-size: 0.875em;
  }
}
