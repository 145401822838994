@import '../../../../theme/helpers/units';
@import '../../../../theme/base/colors';

$borderColor: #d9e1e2;

.cpdActivityTableFees {
  width: 100%;
  margin: 0 em(10px) em(20px);
  border-top: 1px solid $borderColor;

  .novoFormField {
    padding: 0;
    margin: em(6px) 0 em(9px);
  }

  .novoFormFieldError {
    display: none;
  }
}

.cpdActivityTableFees__row {
  height: em(64px);
  border-bottom: 1px solid $borderColor;
}

.cpdActivityTableFees__row__name {
  padding-right: 1em;
  font-weight: 600;
  line-height: 1.35em;
}

.cpdActivityTableFees__row__name,
.cpdActivityTableFees__row__amount {
  vertical-align: middle;
}

.cpdActivityTableFees__row--total {
  .cpdActivityTableFees__row__name {
    text-transform: uppercase;
  }

  .cpdActivityTableFees__row__amount {
    padding-right: 1em;
    font-weight: 600;
    text-align: right;
  }
}

.cpdActivityTableFees--approved-mode .cpdActivityTableFees__row__amount:nth-child(2) {
  padding-right: 1em;
  text-align: right;
}
