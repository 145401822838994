@import '../../../../theme/base/colors';
@import '../../../../theme/base/sizes';
@import '../../../../theme/helpers/units';

$statusBoxSuccessIconColor: $green;
$statusBoxSuccessBgColor: rgba($statusBoxSuccessIconColor, 0.05);
$statusBoxErrorIconColor: $red;
$statusBoxErrorBgColor: rgba($statusBoxErrorIconColor, 0.05);
$statusBoxWarningIconColor: $yellow;
$statusBoxWarningBgColor: rgba($statusBoxWarningIconColor, 0.1);
$statusBoxInfoIconColor: $blue;
$statusBoxInfoBgColor: rgba($statusBoxInfoIconColor, 0.1);
$statusBoxTitleFontSize: 20px;
$statusBoxTextFontSize: 18px;
$statusBoxCheckFontSize: 32px;

.cpdStatusBox {
  display: flex;
  align-items: flex-start;
  padding: em(26px);
  margin-bottom: em(30px);

  blockquote {
    font-style: italic;
  }
}

.cpdStatusBox__icon {
  margin-right: em(16px, $statusBoxCheckFontSize);
  font-size: em($statusBoxCheckFontSize);
}

.cpdStatusBox__title {
  margin-bottom: em(6px, $statusBoxTitleFontSize);
  font-size: em($statusBoxTitleFontSize);
  font-weight: 700;
  line-height: em(28px, $statusBoxTitleFontSize);
  letter-spacing: -0.01em;
}

.cpdStatusBox__description {
  font-size: em($statusBoxTextFontSize);
  line-height: em(26px, $statusBoxTextFontSize);

  &:first-child {
    margin-top: 0.15em;
  }
}

.cpdStatusBox--small {
  padding: 1em 1.25em;
  margin: 1.25em 0;
  font-size: 0.875em;
}

.cpdStatusBox--success {
  background-color: $statusBoxSuccessBgColor;

  .cpdStatusBox__icon {
    color: $statusBoxSuccessIconColor;
  }

  .cpdStatusBox__icon::before {
    content: 'check_circle';
  }
}

.cpdStatusBox--error {
  background-color: $statusBoxErrorBgColor;

  .cpdStatusBox__icon {
    color: $statusBoxErrorIconColor;
  }

  .cpdStatusBox__icon::before {
    content: 'error';
  }
}

.cpdStatusBox--warning {
  background-color: $statusBoxWarningBgColor;

  .cpdStatusBox__icon {
    color: $statusBoxWarningIconColor;
  }

  .cpdStatusBox__icon::before {
    content: 'warning';
  }
}

.cpdStatusBox--info {
  background-color: $statusBoxInfoBgColor;

  .cpdStatusBox__icon {
    color: $statusBoxInfoIconColor;
  }

  .cpdStatusBox__icon::before {
    content: 'info';
  }
}

@media screen and (max-width: $medium-small-screen-width) {
  .cpdStatusBox {
    font-size: 0.9em;
  }
}
