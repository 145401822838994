@import '../../../theme/helpers/units';
@import '../../../theme/base/colors';

.cpdImportantDatesAdmin {
  display: flex;
  flex-direction: column;
}

.cpdImportantDatesAdmin__form-state {
  font-size: em(18px);
  font-weight: bold;
  line-height: em(32px);
  color: $red;
}

.cpdImportantDatesAdmin__form-state--active {
  color: $green;
}

.cpdImportantDatesAdmin__content {
  font-size: em(16px);
  font-weight: normal;
  line-height: em(24px);
}

.cpdImportantDatesAdmin__content__date {
  font-weight: bold;
}
