// Theme
// -------------------------------
@import 'html';
@import 'base/all';
@import 'objects/all';

body {
  --novo-field-readonly-margin: auto 0 1.15em;
}

html,
body,
#cpd {
  height: 100%;
  min-height: 100vh;
}

strong {
  font-weight: 700;
}

.novoForm::after,
.novoTabPanel::after {
  display: block;
  clear: both;
  content: '';
}

.novoForm--readonly {
  .novoFormField__raw:empty::before {
    content: '—';
  }

  @include readonly-checkbox;
}

.novoFields__text {
  margin: 0 em(12px);
}

.cpdLineBreak::before {
  white-space: pre;
  content: '\a';
}

.cpdStretchContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.cpdHiddenForPrint {
  width: 100%;
}

.cpdVisibleForPrint {
  display: none;
}

.cpdFormSingleField {
  --novo-field-margin: #{em(8px) 0};
  --novo-field-label-required-symbol: '';

  width: 100%;

  .novoFormOptionsField--inline {
    margin-top: 0;
  }

  .novoFormFieldError {
    display: none;
  }

  .novoFormField--checkbox .novoFormFieldLabel {
    margin-bottom: 0;
  }

  .novoFormField--error.novoFormField--checkbox .novoFormFieldLabel {
    color: var(--novo-field-error-color);
  }
}

.cpdFormSendEmailCheckbox {
  display: inline-flex;

  .novoFormField--checkbox {
    margin: 0.5em 0 0;
  }
}

.cpdFormCancelButton {
  width: 100%;
  margin: var(--novo-field-padding);
  text-align: right;
}

.cpdRevisionClaimAmountApproved {
  float: right;
  margin: 0 #{em(12px)} -1em;
  color: $dark-gray;

  strong {
    color: $black;
  }
}

.cpdFieldTextPrecision {
  margin-top: rem(4px);
  font-size: 0.875em;
  font-weight: bold;
}

// stylelint-disable-next-line
.ag-watermark {
  display: none !important;
}

@media screen and (max-width: $medium-small-screen-width) {
  .cpdStretchContainer .novoFormField--width25 {
    width: 50%;
  }
}

@media screen and (max-width: $small-screen-width) {
  .cpdLineBreak::before {
    content: ' ';
  }
}

@media screen and (max-width: $very-small-screen-width) {
  .cpdStretchContainer .novoFormField--width25 {
    width: 100%;
  }
}

@media print {
  .cpdHiddenForPrint {
    display: none;
  }

  .cpdVisibleForPrint {
    display: block;
  }
}
