@import '../../../theme/helpers/units';

$initializingSize: 120px;

.cpdInitializing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cpdInitializing__image {
  width: em($initializingSize);
  height: em($initializingSize);
}
