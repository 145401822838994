@import '../../../../theme/base/colors';
@import '../../../../theme/base/elevations';
@import '../../../../theme/base/sizes';
@import '../../../../theme/helpers/units';

$siteLayoutTitleFontSize: 32px;
$siteContentTitleFontSize: 24px;
$siteLayoutDefaultBgColor: $lightest-gray;
$siteLayoutContentBorderColor: #e9e9eb;
$siteLayoutTitleColor: $dark-blue;
$siteLayoutMobileHorizontalPadding: 32px;
$siteLayoutMobileFormFieldsTitleFontSize: 20px;

.cpdSiteLayout {
  min-height: 100vh;
  padding: em(36px) em(24px) em(72px);
  background-color: $siteLayoutDefaultBgColor;
}

.cpdSiteLayout__logo {
  margin-bottom: em(16px);
  text-align: center;
}

.cpdSiteLayout__logo__img {
  width: em(132px);
  height: auto;
}

.cpdSiteLayout__panel,
.cpdSiteLayout__footer {
  max-width: em(1140px);
  margin: 0 auto;
}

.cpdSiteLayout__panel {
  position: relative;
  padding: em(24px) em(48px);
  background: white;
  border: 1px solid $siteLayoutContentBorderColor;
  box-shadow: $elevation-02;
}

.cpdSiteLayout__footer {
  padding-top: em(12px);
}

.cpdSiteLayout__panel--decoration {
  padding-bottom: em(320px);
  background: url('~/public/assets/footer.svg') no-repeat bottom, white;
}

.cpdSiteLayout__panel__header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto em(18px) auto;
}

.cpdSiteLayout__panel__header__logo {
  z-index: 1;
  display: block;
  width: em(130px);
  height: auto;
}

.cpdSiteLayout__panel__content {
  position: relative;
}

.cpdSiteLayout__panel__content__header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: em(18px, $siteLayoutTitleFontSize);
}

.cpdSiteLayout__panel__content__title {
  display: flex;
  align-items: center;
  font-size: em($siteLayoutTitleFontSize);
  font-weight: 700;
  line-height: em(48px, $siteLayoutTitleFontSize);
  color: $siteLayoutTitleColor;
  letter-spacing: -0.01em;
}

.cpdSiteLayout__panel__content__title .cpdTag {
  margin-left: 1em;
  font-size: 0.38em;
}

.cpdSiteLayout__panel__content__subtitle {
  margin: em(30px) 0 1em;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  text-transform: uppercase;
}

.cpdSiteLayout__panel__content__text,
.cpdSiteLayout__panel__content__list__item {
  position: relative;
  display: block;
  margin: 1.5em 0;
  font-size: 1em;
  line-height: 1.5em;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.cpdSiteLayout__panel__content__text--emphasis {
  font-style: italic;
}

.cpdSiteLayout__panel__content__text--strong {
  font-weight: bold;
}

.cpdSiteLayout__panel__content__text + .cpdSiteLayout__panel__content__list {
  margin-top: -0.75em;
}

.cpdSiteLayout__panel__content__link {
  color: $blue;

  &:hover {
    color: darken($blue, 5%);
  }
}

.cpdSiteLayout__panel__content__list {
  padding-left: 1.5em;
}

ol.cpdSiteLayout__panel__content__list {
  counter-reset: list;
}

.cpdSiteLayout__panel__content__list__item {
  margin: 0.25em 0;

  &::before {
    position: absolute;
    transform: translateX(-1.25em);
  }
}

ul > .cpdSiteLayout__panel__content__list__item::before {
  content: '\2022';
}

ol > .cpdSiteLayout__panel__content__list__item::before {
  content: counter(list) '.';
  counter-increment: list;
}

.cpdSiteLayout--medium .cpdSiteLayout__panel {
  max-width: em(900px);
}

.cpdSiteLayout--large {
  .cpdSiteLayout__panel__content {
    min-height: em(300px);
  }

  .novoForm {
    padding-top: em(14px);
  }
}

.cpdSiteLayout--small {
  .cpdSiteLayout__panel {
    max-width: em(520px);
  }

  .cpdSiteLayout__panel__header {
    margin: 0 auto em(48px) auto;
  }
}

.cpdSiteLayout__panel--home {
  padding-bottom: em(105px);
  background: linear-gradient(180deg, #004663 1.59%, #006c9e 25%);
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    background: url('~/public/assets/corner.svg') no-repeat top left, transparent;
    background-size: 43.25%;
  }

  .cpdSiteLayout__panel__header {
    justify-content: flex-end;
    margin-bottom: em(120px);
  }

  .cpdSiteLayout__panel__header__logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 23.25%;
    padding: em(48px);
  }

  .cpdSiteLayout__panel__content {
    margin-left: 30%;
  }

  .cpdSiteLayout__panel__content__title,
  .cpdSiteLayout__panel__content__text {
    color: white;
  }

  .cpdSiteLayout__panel__content__title {
    display: block;
    margin-bottom: em(25px, $siteContentTitleFontSize);
  }
}

@media screen and (max-width: $large-screen-width) {
  .cpdSiteLayout--large {
    padding: 0;

    .cpdSiteLayout__panel {
      min-height: 100vh;
      border: 0;
    }
  }

  .cpdSiteLayout__panel--home {
    &::before {
      background-size: 40%;
    }

    .cpdSiteLayout__panel__header__logo {
      width: 20%;
      padding: em(32px);
    }

    .cpdSiteLayout__panel__content {
      margin-left: 25%;
    }
  }
}

@media screen and (max-width: $medium-small-screen-width) {
  .cpdSiteLayout__panel--home {
    &::before {
      background-size: 30%;
    }

    .cpdSiteLayout__panel__header__logo {
      width: 17.5%;
      padding: em(24px);

      .cpdLogoLongName {
        display: none;
      }
    }

    .cpdSiteLayout__panel__content {
      margin-left: 15%;
    }
  }
}

@media screen and (max-width: $small-screen-width) {
  .cpdSiteLayout__panel__header__logo {
    width: em(115px);
  }

  .cpdSiteLayout__panel {
    padding: em(24px) em($siteLayoutMobileHorizontalPadding) em(64px);

    .novoForm__fields {
      width: fit-content;
      padding: 0 em(24px) em(24px);
      margin: 1.75em #{em(-$siteLayoutMobileHorizontalPadding)} #{em(36px)};
    }

    .novoForm__fields__title {
      position: relative;
      top: 0;
      left: em(8px, $siteLayoutMobileFormFieldsTitleFontSize);
      width: calc(100vw - #{$siteLayoutMobileHorizontalPadding * 2});
      padding-bottom: em(6px, $siteLayoutMobileFormFieldsTitleFontSize);
      transform: translateY(-50%);
    }
  }

  .cpdSiteLayout--small .cpdSiteLayout__panel {
    padding-bottom: em(36px);
  }

  .cpdSiteLayout__panel--decoration {
    padding-bottom: em(168px);
  }

  .cpdSiteLayout__panel--home {
    padding-bottom: em(64px);

    &::before {
      background-size: 180px;
    }

    .cpdSiteLayout__panel__header__logo {
      width: 110px;
      padding: em(14px);
    }

    .cpdSiteLayout__panel__content {
      margin-left: 0;
    }
  }

  .cpdSiteLayout__panel__content__title {
    font-size: em(24px);
  }
}

@media screen and (max-width: $very-small-screen-width) {
  .cpdSiteLayout__panel .novoForm .novoButton--primary {
    width: 100%;
  }
}

@media print {
  .cpdSiteLayout {
    padding: 0;
    background-color: transparent;

    .cpdStatusBox--small {
      display: none;
    }
  }

  .cpdSiteLayout__panel {
    padding: 0;
    border: none;
    box-shadow: none;
  }

  .cpdSiteLayout__panel--decoration {
    background: none;
  }

  .cpdSiteLayout__panel__header,
  .cpdSiteLayout__panel__content__toolbar {
    display: none;
  }

  .cpdSiteLayout__panel__content__title {
    font-size: 1.75em;
    line-height: 1.8em;
    color: black;

    .cpdTag {
      display: none;
    }
  }
}
