@import '../../../../theme/helpers/units';
@import '../../../../theme/base/colors';
@import '../../../../theme/base/elevations';
@import '../../../../theme/base/sizes';

$modalTitleFontSize: 24px;
$modalTitleMobileFontSize: 20px;
$modalBorderColor: $light-gray;
$modalCloseButtonColor: $medium-gray;
$modalCloseButtonColorHovered: darken($medium-gray, 10%);
$modalMarginTop: 100px;
$modalMobileMarginTop: 50px;
$modalMaxWidth: 1087px;

.cpdModalContainer {
  padding: 0 1em;
}

.cpdModal {
  display: flex;
  flex-direction: column;
  max-width: $modalMaxWidth;
  height: 100%;
  max-height: calc(85vh - #{$modalMarginTop});
  margin: $modalMarginTop auto auto;
  background-color: white;
  border-radius: em(8px);
  box-shadow: $elevation-06;
}

.cpdModal__header {
  padding: em(20px) em(24px) em(16px);
  border-bottom: 1px solid $modalBorderColor;
}

.cpdModal__header__title {
  font-size: em($modalTitleFontSize);
  font-weight: 700;
  line-height: em(36px, $modalTitleFontSize);
  letter-spacing: -1%;
}

.cpdModal__header__close {
  float: right;
  padding: 0;
  margin-left: 0.5em;
  color: $modalCloseButtonColor;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;

  &:hover {
    color: $modalCloseButtonColorHovered;
  }
}

.cpdModal__content {
  flex: 1;
  padding: em(20px) em(24px) em(32px);
  overflow: auto;
}

.cpdModal__content--host {
  padding: 0;
  overflow: hidden;
}

.cpdModal__footer {
  padding: em(16px) em(24px);
  text-align: right;
  border-top: 1px solid $modalBorderColor;
}

.cpdModalContainer--maximize {
  height: 100vh;
  padding: 2em 3.5em;

  .cpdModal {
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    margin: auto;
  }
}

@media screen and (max-width: $medium-screen-width) {
  .cpdModalContainer--maximize {
    padding: 1.5em 2em;
  }
}

@media screen and (max-width: $small-screen-width) {
  .cpdModal__header__title {
    font-size: $modalTitleMobileFontSize;
  }

  .cpdModal {
    max-height: calc(95vh - #{$modalMobileMarginTop});
    margin: $modalMobileMarginTop auto auto;
  }
}
