@import '../../../../theme/helpers/units';

$sidePanelPadding: em(24px);
$sidePanelCloseIconFontSize: 28px;

.novoSidePanelFooterActions .novoButton {
  margin-right: 10px;
}

.novoSidePanel__header-nav--host .novoTabList {
  padding-left: $sidePanelPadding;
}

.novoSidePanel__header__close {
  line-height: em(48px, $sidePanelCloseIconFontSize);
}

.novoSidePanel > .novoForm {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0;
  overflow-y: auto;
}
