@import '../../../../theme/helpers/units';
@import '../../../../theme/base/colors';

$adminTitleFontSize: 32px;
$adminVersionFontSize: 12px;
$adminVersionColor: $dark-gray;

.cpdAdminLayout {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.cpdAdminLayout__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: em(24px) em(24px) 0;
}

.cpdAdminLayout__content__header {
  display: flex;
  align-items: center;
  margin-bottom: em(16px, $adminTitleFontSize);
}

.cpdAdminLayout__content__header__title {
  flex: 1;
  font-size: em($adminTitleFontSize);
  font-weight: bold;
  line-height: em(48px, $adminTitleFontSize);
  letter-spacing: -0.01em;
}

.cpdAdminLayout__content__header__title__secondary {
  padding-left: em(26px);
  font-size: 0.5625em;
  font-weight: bold;
}

.cpdAdminLayout__content__grid {
  position: relative;
  flex: 1;
  margin: 0 em(-24px);
  background-color: $light-gray;
}

.cpdAdminLayout__version {
  position: absolute;
  bottom: em(9px, $adminVersionFontSize);
  left: em(18px, $adminVersionFontSize);
  font-size: em($adminVersionFontSize);
  color: $adminVersionColor;
  user-select: none;
}
