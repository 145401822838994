@import '../../../../theme/helpers/units';
@import '../../../../theme/base/colors';

$imageVisualizerBgColor: $light-gray;
$imageVisualizerShadowColor: rgba($medium-gray, 0.9);
$imageVisualizerToolsBgColor: rgba($dark-gray, 0.9);
$imageVisualizerToolsItemColor: #fff;
$imageVisualizerToolsItemBgColorHovered: rgb(white, 0.2);
$imageVisualizerToolsItemColorDisabled: darken($imageVisualizerToolsItemColor, 40%);

.cpdImageVisualizer {
  position: relative;
  height: 100%;
  background-color: $imageVisualizerBgColor;
}

.cpdImageVisualizer .cpdImageVisualizer__wrapper {
  width: 100%;
  height: 100%;
}

.cpdImageVisualizer__wrapper__content {
  box-shadow: 0 em(4px) em(15px) em(2px) $imageVisualizerShadowColor;
}

.cpdImageVisualizer__wrapper--panning,
.cpdImageVisualizer__wrapper--panning * {
  cursor: all-scroll;
}

.cpdImageVisualizerTools {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 1;
  padding: 2px;
  background-color: $imageVisualizerToolsBgColor;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 500ms ease-out;
  transform: translateX(-50%);
}

.cpdImageVisualizerTools--visible {
  opacity: 1;
  transition: opacity 50ms ease-out;
}

.cpdImageVisualizerTools__item {
  padding: 0.5em;
  color: $imageVisualizerToolsItemColor;
  cursor: pointer;
  background: none;
  border: 0;
  transition: background-color 50ms ease-out;

  &:hover {
    background-color: $imageVisualizerToolsItemBgColorHovered;
  }

  &:disabled {
    color: $imageVisualizerToolsItemColorDisabled;
    pointer-events: none;
    cursor: default;
    background: none;
  }
}

.cpdImageVisualizerTools__item--zoom-in::before {
  content: 'add';
}

.cpdImageVisualizerTools__item--zoom-out::before {
  content: 'remove';
}

.cpdImageVisualizerTools__item--reset::before {
  content: 'zoom_out_map';
}
