@import '../../../../theme/helpers/units';
@import '../../../../theme/base/colors';

$titleFontSize: 24px;

.cpdPanel {
  padding: em(20px) em(24px);
  margin: em(12px) 0;
  background-color: $lightest-gray;
}

.cpdPanel__title {
  flex: 1;
  font-size: em($titleFontSize);
  font-weight: bold;
  line-height: em(36px, $titleFontSize);
  letter-spacing: -0.01em;
}

.cpdPanel__content {
  margin: em(6px) 0;
}
