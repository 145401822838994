@import '../base/typography';

@mixin readonly-checkbox {
  .novoFormField--checkbox {
    .novoFormField__input {
      display: none;
    }

    .novoFormFieldLabel {
      display: flex;

      &::before {
        display: inline-block;
        margin-right: 0.5em;
        font-family: $font-icon;
      }
    }

    .novoFormField__input:checked + .novoFormFieldLabel {
      color: black;
    }
  }

  .novoFormField--checkbox .novoFormField__input[type='checkbox'] {
    & + .novoFormFieldLabel::before {
      content: 'check_box_outline_blank';
    }

    &:checked + .novoFormFieldLabel::before {
      content: 'check_box';
    }
  }

  .novoFormField--checkbox .novoFormField__input[type='radio'] {
    & + .novoFormFieldLabel::before {
      content: 'radio_button_unchecked';
    }

    &:checked + .novoFormFieldLabel::before {
      content: 'radio_button_checked';
    }
  }
}
